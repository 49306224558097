import React, {useContext, useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import "./ChangePassword.css"
import {authContext} from "../../context/authContext";
import {signInWithEmailAndPassword, updatePassword} from "firebase/auth";
import {auth} from "../../firebase-config";


const ChangePassword = () => {
    useEffect(() => {
        document.title = "User Password Change";
    }, []);
    const user = useContext(authContext);
    const [errors, setErrors] = useState('');
    const [messages, setMessages] = useState('');
    const [loading, setLoading] = useState(false);
    const [changeSuccessful, setChangeSuccessful] = useState(false);

    const initialValues = {email: "", password: "",}

    const validateForm = (values) => {
        const errors = {};
        if (!values.password) {
            errors.password = "Password is required";
        }
        if (!values.new_password_1) {
            errors.new_password_1 = "New password is required";
        }
        if (!values.new_password_2) {
            errors.new_password_2 = "New password is required";
        }
        if (values.new_password_1.localeCompare(values.new_password_2) !== 0) {
            errors.passwords_mismatch = "Passwords do not match";
        }
        return errors;
    }

    const changePassword = async (email, oldPassword, newPassword) => {
        console.log(`[CHANGE_PWD] email: ${email} - old password: ${oldPassword}`)
        try {
            await signInWithEmailAndPassword(auth, email, oldPassword)
        } catch (error) {
            throw Error("Invalid email/password")
        }
        try {
            await updatePassword(user, newPassword)
        } catch (error) {
            throw Error("Could not update password")
        }
        try {
            await signInWithEmailAndPassword(auth, email, newPassword)
        } catch (error) {
            throw Error("Could not reauthenticate with new password")
        }
    }

    const onSubmit = (values) => {
        setLoading(true);
        setChangeSuccessful(false)
        changePassword(user.email, values.password, values.new_password_1)
            .then(() => {
                console.log('Password updated successfully');
                setChangeSuccessful(true)
                window.location.reload();
            })
            .catch((error) => {
                const errorMessage = error.message;
                setErrors(errorMessage);
            });
        setLoading(false);
    }

    useEffect(() => {
        if(changeSuccessful) {
            setMessages('Password updated successfully');
        }
    }, [changeSuccessful]);

    return (<>
        <main>
            <section style={{marginTop: 30}}>
                <div>
                    <div>
                        <section>
                            <div className="messages d-flex justify-content-center centered-form"
                                 style={{display: "inline-block"}}>
                                {errors && <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    style={{color: "red"}}>
                                    {errors && errors}
                                </div>}
                                {messages && <div
                                    className="alert alert-success alert-dismissible fade show"
                                    style={{color: "green"}}>
                                    {messages && messages}
                                </div>}
                            </div>
                            <div className="d-flex justify-content-center centered-form">
                                <Formik initialValues={initialValues} validate={validateForm}
                                        onSubmit={(values) => onSubmit(values)} validateOnBlur={false}
                                        validateOnChange={false} enableReinitialize={true}>
                                    {({
                                          values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting
                                      }) => (<Form>
                                        <h3 style={{textAlign: "center"}}>User Password Change</h3>
                                        <div>
                                            <div className="form-group mt-2">
                                                <Field type="email" id="email" name="email" value={user.email}
                                                       className="textinput form-control"
                                                       maxLength="254"
                                                       size="35"
                                                       disabled={true}
                                                       onChange={handleChange}
                                                       autocomplete="username"
                                                       placeholder="Email address"/>
                                                <p style={{color: 'red'}}>
                                                    {errors.email && touched.email && errors.email}
                                                </p>
                                            </div>
                                            <div className="form-group mt-2">
                                                <Field type="password" id="password" name="password"
                                                       className="textinput form-control"
                                                       value={values.password} onChange={handleChange}
                                                       autocomplete="current-password"
                                                       placeholder="Old Password"/>
                                                <p style={{color: 'red'}}>
                                                    {errors.password && touched.password && errors.password}
                                                </p>
                                            </div>
                                            <div className="mt-3 text-start"> Choose New Password</div>
                                            <div className="form-group mt-2">
                                                <Field type="password" id="new_password_1" name="new_password_1"
                                                       className="textinput form-control"
                                                       value={values.new_password_1} onChange={handleChange}
                                                       autocomplete="new-password"
                                                       placeholder="New Password"/>
                                                <p style={{color: 'red'}}>
                                                    {errors.new_password_1 && touched.new_password_1 && errors.new_password_1}
                                                </p>
                                            </div>
                                            <div className="form-group mt-2">
                                                <Field type="password" id="new_password_2" name="new_password_2"
                                                       className="textinput form-control"
                                                       value={values.new_password_2} onChange={handleChange}
                                                       autocomplete="new-password"
                                                       placeholder="Confirm New Password"/>
                                                <p style={{color: 'red'}}>
                                                    {errors.new_password_2 && touched.new_password_2 && errors.new_password_2}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <button type="submit" className="btn btn-primary"
                                                    style={{width: "330px", fontWeight: 600}}>
                                                <span>{loading ? "Updating password..." : " Change Password "}</span>
                                            </button>
                                        </div>
                                    </Form>)}
                                </Formik>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </main>
    </>)
}

export default ChangePassword