import {signOut} from 'firebase/auth';
import {auth, useLocalLogin} from "../../firebase-config";
import {CE_LOGOUT} from "../../constants/routes";

const Logout = () => {
    signOut(auth)
        .then(() => {
            if (useLocalLogin) {
                window.location.reload();
            } else {
                window.location.replace(CE_LOGOUT);
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`[SIGN_OUT] error ${errorCode} - ${errorMessage}`)
        });
}
export default Logout