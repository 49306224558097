import Map from './routes/Map'
import Login from './routes/login/Login'
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {
    ACCT_CHANGE_PWD, ACCT_PASSWORD_RESET, ACCT_PASSWORD_RESET_DONE, ACCT_PROFILE, LANDING, LOGIN, MAP
} from './constants/routes'
import NavigationBar from "./routes/Navigation";
import ChangePassword from "./routes/account/ChangePassword";
import {AuthProvider} from "./context/authContext";
import ProtectedRoute from "./routes/ProtectedRoute";
import UserProfile from "./routes/account/Profile";
import PasswordReset from "./routes/account/PasswordReset";
import PasswordResetDone from "./routes/account/PasswordResetDone";
import {useState} from "react";

function App() {
    const [project, setProject] = useState("")
    return (
        <BrowserRouter>
            <AuthProvider>
                <NavigationBar
                    project={project}
                    setProject={setProject}
                >
                    <Routes>
                        <Route index path={LANDING} element={<Login/>}/>
                        <Route path={LOGIN} element={<Login/>}/>
                        <Route path={ACCT_PASSWORD_RESET} element={<PasswordReset/>}/>
                        <Route path={ACCT_PASSWORD_RESET_DONE} element={<PasswordResetDone/>}/>
                        <Route path={MAP} element={<ProtectedRoute redirectPath={LOGIN}>
                            <Map project={project}/>
                        </ProtectedRoute>}/>
                        <Route path={ACCT_CHANGE_PWD} element={<ProtectedRoute redirectPath={LOGIN}>
                            <ChangePassword/>
                        </ProtectedRoute>}/>
                        <Route path={ACCT_PROFILE} element={<ProtectedRoute redirectPath={LOGIN}>
                            <UserProfile/>
                        </ProtectedRoute>}/>
                    </Routes>
                </NavigationBar>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App