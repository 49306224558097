
const sky = {
    'id': 'sky',
    'type': 'sky',
    'paint': {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [260, 80],
        'sky-atmosphere-sun-intensity': 2,
        'sky-atmosphere-color': 'rgb(102, 162, 196)',
        'sky-atmosphere-halo-color': 'rgba(252, 195, 50, 0.5)'
    }
}

export default sky;