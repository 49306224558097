import { ReferenceLine, XAxis } from 'recharts';
import { Bar, BarChart } from 'recharts';
import exposure_data from '../data/all_projects_exposure.json'

export function BinnedGraph({ dataKey, marker, binCount, overrideMarker }) {
    function range(start, stop, step) {
        if (typeof stop == 'undefined') {
            stop = start;
            start = 0;
        }
    
        if (typeof step == 'undefined') {
            step = 1;
        }
    
        if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
            return [];
        }
    
        var result = [];
        for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
            result.push(i);
        }
    
        return result;
    }

    function GetBins(binCount, min, max) {
        const step = (max - min) / binCount
        return range(min, max, step)
    }

    function BinData(data, marker, binCount, minValue, maxValue) {
        const bins = GetBins(binCount, minValue, maxValue)

        let i = 0
        let binnedData = []
        while (i < binCount) {
            const binMin = bins[i]
            const binMax = bins[i + 1]

            let marked = false
            if (marker >= binMin && marker < binMax) {
                marked = true
            }

            let label = (i+1).toFixed(2)
            if (i == 0) label = minValue.toFixed(2)
            if (i == binCount - 1) label = maxValue.toFixed(2)

            binnedData.push(
                {
                    'binNum': i,
                    'count': data.filter(i => i >= binMin && i < binMax).length,
                    'freq': data.filter(i => i >= binMin && i < binMax).length / data.length,
                    'marked': marked,
                    'label': label
                }
            )
            i++;
        }
        return binnedData
    }

    function FindMarkedBin(data) {
        const filtered = data.filter((x) => x.marked == true)
        if (filtered.length == 0) {
            return -1
        }
        return filtered[0].binNum
    }

    const min = Math.min(...exposure_data[dataKey])
    const max = Math.max(...exposure_data[dataKey])
    const binnedData = BinData(exposure_data[dataKey], marker, binCount, min, max)
    return <div className='binned-graph'>
        <MiniBarChart 
            data={binnedData} 
            autoMarkedBin={FindMarkedBin(binnedData)} 
            manualMarkedBin={overrideMarker}
            ticks={[min.toFixed(2), max.toFixed(2)]}
        />
    </div>
}

function MiniBarChart({ data, autoMarkedBin, manualMarkedBin, ticks }) {
    return (
        <BarChart
            width={150}
            height={100}
            className='distribution-bar-chart'
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
            }}
        >
            <XAxis dataKey='label' ticks={ticks} interval='preserveStartEnd'/>
            <Bar dataKey='freq' fill='#000000' />
            {autoMarkedBin >= 0} && <ReferenceLine x={autoMarkedBin.toFixed(2)} stroke='#FF0000' strokeWidth='2' />
            <ReferenceLine x={manualMarkedBin?.toFixed(2)} stroke='#FF0000' strokeWidth='2' />
        </BarChart>
    );
}
