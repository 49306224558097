import {useEffect, useState} from 'react'
import {Navigate} from 'react-router-dom'
import {auth} from "../firebase-config";
import {Spinner} from "react-bootstrap";

const ProtectedRoute = ({children, redirectPath}) => {
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        auth.authStateReady().then(() => {
            setUser(!!auth.currentUser);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div className="flex items-center justify-center h-[400px] ">
            <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
        </div>
    } else {
        if (user) {
            return children
        } else {
            return <Navigate to={redirectPath}/>
        }
    }
};

export default ProtectedRoute