export const LANDING = '/';
export const LOGIN = '/login';
export const MAP = '/map';
export const ACCT_CHANGE_PWD = '/account/password';
export const ACCT_PROFILE = '/account/profile';
export const ACCT_PASSWORD_RESET = '/account/password_reset';
export const ACCT_PASSWORD_RESET_DONE = '/account/password_reset/done';
export const CE_MAIN = process.env.REACT_APP_EXPLORER_URL
export const CE_LOGIN = process.env.REACT_APP_EXPLORER_URL.concat('/account/login/?next=/')
export const CE_LOGOUT = process.env.REACT_APP_EXPLORER_URL.concat('/account/logout/?next=/')
