import './dropdown.css'

const nameMapping = {
    'wildfire_ai': 'Wildfire Probability',
    'carbon_percent_loss': 'Wildfire-Driven Carbon Loss Fraction',
    'pga': 'Earthquake',
    'pest': 'Pests',
    'water_balance': 'Water Balance',
    'fire_obs': 'Observed Wildfire',
    'extreme_precip': 'Extreme Precipitation',
    'cyclone': 'Cyclone Probability',
    'cyclone_obs': 'Observed Cyclones',
    'slr': 'Sea Level Rise'
}

function formatLayerName(activeDataset, rawLayerName) {
    return nameMapping[rawLayerName.replace(activeDataset, '')]
}

export default function Dropdown({
    activeDataset, 
    allDatasets, 
    setActiveDataset, 
    availableLayers,
    layerGroup,
    setLayerGroup,
    mapLoaded,
    project
}) {

    function handleActiveDatasetChange(ds) {
        setActiveDataset(ds)
        ds && setLayerGroup(ds['type'] === 'Blue Carbon' ? 'cyclone_obs' : 'fire_obs')
    }

    if (mapLoaded) setLayerGroup(layerGroup);

    return <nav class="menu">
        <ol>
        <li class="menu-item">
            <a href="#0">{project}</a>
            <ol class="sub-menu">
            {
                allDatasets && allDatasets
                    .filter(x => x.status === 'Processing Completed')
                    .map(x => <li class="menu-item" key={x.name} onClick={() => handleActiveDatasetChange(x)}>{x.name}</li>)
            }
            </ol>
        </li>
        <li class="menu-item">
            <a href="#0">Layers</a>
            <ol class="sub-menu">
            {
                Object.keys(availableLayers)
                    .map(x => <li class="menu-item" key={x} onClick={() => setLayerGroup(x)}>{formatLayerName(activeDataset, x)}</li>)
            }
            </ol>
        </li>
        </ol>
    </nav>
}

