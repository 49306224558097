export default class RasterInterpolatedProto {
    constructor({
        id, //Unique ID
        layer_type,
        layer
    }) {
        this.id = id
        this.layer = layer
        this.layer_type = layer_type
        this.display_legend = false
    }

    get MBLayer() {
        return this.layer
    }

    // get Legend() {
    //     return Object.assign(
    //         this.legend,
    //         {
    //             layer_title: this.layer_title,
    //             layer_type: this.layer_type,
    //             suffix: this.legend_suffix
    //         }
    //     )
    // }
}
