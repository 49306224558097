
import { useEffect, useRef, useState, useContext } from 'react'
import { authContext } from "../../context/authContext";
import "./FileSubmission.css"
import {
    tile_builder_url as server_url,
} from '../../config';
import Tooltip from './Tooltip';
import bluecarbon from './thumbnails/bluecarbon.jpg'
import forestcarbon from './thumbnails/forestcarbon.jpg'

const personas = {
    "NCS": 1,
    "Blue Carbon": 2
}


const persona_display = {
    "NCS": {
        'title': "Forest Carbon",
        'thumbnail': forestcarbon,
        'color': '#41cc4a',
    },
    "Blue Carbon": {
        'title': 'Blue Carbon',
        'thumbnail': bluecarbon,
        'color': '#41c2cc',
    }
}


function SubmissionStep({ step_number, children }) {
    return (
        <span className='submission-step-container'>
            <div className="submission-step-number-container">
                <div className='submission-step-number'>
                    {step_number}
                </div>
            </div>
            <div className='submission-step-content'>
                {children}
            </div>
        </span>
    )
}


function DatasetStatusView({ dataset }) {
    return <div className='dataset-status-view'>
        <span className='dataset-status-title'>{dataset['name']}</span>
        <span className='dataset-status-title'>{dataset['status']}</span>
    </div>
}


function PersonaSelection({ persona, selectedPersona, setSelectedPersona }) {
    return <div
        className={`persona-selector${persona === selectedPersona ? ' selected' : ''}`}
        style={{
            backgroundColor: `${persona_display[persona]['color']}`,
        }}
        onClick={(e) => setSelectedPersona(persona)}>
        <div className='persona-selector-text'>
            {persona_display[persona]['title']}
        </div>
    </div>
}


export default function SubmissionSplash({ setSplashOn, project }) {
    const [projectName, setProjectName] = useState();
    const [selectedPersona, setSelectedPersona] = useState();
    const inputRef = useRef(null);
    const projectNameRef = useRef(null);
    const personaRef = useRef(null);
    const [file, setFile] = useState(null)
    const [inFlight, setInFlight] = useState([])
    const [errors, setErrors] = useState([]);

    function getInFlight() {
        user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${server_url}/projects`, {
                        method: 'GET',
                        withCredentials: true,
                        credentials: 'include',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': project,
                        }
                    })
                        .then(r => r.json())
                        .then(i => setInFlight(i))
                        .catch((err) => console.error(err))
                } else {
                    console.error('api key not available for user')
                }
            })

        setTimeout(getInFlight, 5_000)
    }

    useEffect(() => {
        console.log("Selected persona:", selectedPersona)
        personaRef.current = selectedPersona
    }, [selectedPersona])

    useEffect(() => {
        getInFlight()
    }, [project])

    const handleFileChange = (e) => {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);
    };

    const user = useContext(authContext);
    const postToServer_PUBSUB = (data) => {
        console.log('Posting to pubsub', data)
        user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${server_url}/projects`, {
                        method: 'POST',
                        body: JSON.stringify(data),
                        withCredentials: true,
                        credentials: 'include',
                        timeout: 3600,
                        // 👇 Set headers manually for single file upload
                        headers: {
                            'Content-Type': "application/json",
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': project,
                        },
                    }).then((res) => {
                        return new Promise((resolve) => res.json()
                          .then((json) => resolve({
                            status: res.status,
                            statusText: res.statusText,
                            ok: res.ok,
                            json,
                          })));
                      }).then((resp) => {
                        if (!resp.ok) {
                            setErrors(resp)
                        } else {
                            setErrors([])
                        }
                      }).catch(err => setErrors({
                        status: 500,
                        statusText: "Internal Server Error",
                        ok: false,
                        json: {
                            "detail": err.toString()
                        }
                      }))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleUploadClick = () => {
        if (!file) {
            return;
        }
        file.text()
            .then(geojson => {
                postToServer_PUBSUB(
                {
                    "projectName": projectNameRef.current.value,
                    "geojson": JSON.parse(geojson),
                    "version": process.env.REACT_APP_NCS_VERSION,
                    "type": personaRef.current
                })
            })
    };

    return <div className='submission-splash-background'>
        <div>
            <h1>Upload a Project</h1>
            <SubmissionStep step_number={1}>
                <Tooltip trigger={<h2>Project Name</h2>}>
                    <div>Supply a name for your project</div>
                </Tooltip>
                <input
                    type="text"
                    ref={projectNameRef}
                    onChange={(e) => setProjectName(e.target.value)}
                />
            </SubmissionStep>
            <SubmissionStep step_number={2}>
                <Tooltip trigger={<h2>Geographies</h2>}>
                    <div>Upload a shapefile or GeoJSON</div>
                </Tooltip>
                <input
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                />
            </SubmissionStep>
            <SubmissionStep step_number={3}>
                <Tooltip trigger={<h2>Type</h2>}>
                    <div>Choose your data type</div>
                </Tooltip>
                <div className='personas-container'>
                    {Object.keys(personas).map(p =>
                        <PersonaSelection
                            key={p}
                            persona={p}
                            selectedPersona={selectedPersona}
                            setSelectedPersona={setSelectedPersona} />
                    )}
                </div>
            </SubmissionStep>
            <div className="messages d-flex justify-content-center centered-form"
                style={{ display: "inline-block" }}>
                {errors && !errors.ok > 0 && errors.json && <div
                    className="alert alert-danger alert-dismissible fade show"
                    style={{ color: "red" }}>
                    Upload Failed: {errors.json["detail"]}
                </div>}
            </div>

            <hr></hr>
            <button style={{"marginLeft": "45%"}} className="btn btn-primary" onClick={handleUploadClick}>Upload</button>
            <hr></hr>
            <div className='in-flight'>
                <h2>Job Status </h2>
                {inFlight.map(i => <DatasetStatusView dataset={i} key={i['name']} />)}
            </div>
        </div>
    </div>
}


export function FileSubmission({project}) {
    const [splashOn, setSplashOn] = useState(false)

    return <div className='file-submission-root'
        onClick={() => setSplashOn(!splashOn)}>
        {
            splashOn &&
            <SubmissionSplash setSplashOn={setSplashOn} project={project}/>
        }
    </div>
}