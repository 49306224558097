import { useEffect, useState, useRef } from "react";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import generateLayers from "../../layers/offsets_demo_layers";

export function useSelection(map, mapLoaded, mapContainer, activeDataset, layerSelectionDependencies, layerVisibilityDependencies, layerGroup) {

    const [selectedFeatures, setSelectedFeatures] = useState([])
    const featuresRef = useRef([])
    const layerSelectionDependenciesRef = useRef([])
    const layerVisibilityDependenciesRef = useRef([])
    const [selectableLayers, setSelectableLayers] = useState([])
    const selectableLayersRef = useRef()

    useEffect(() => {
        // console.log(`Active Dataset Changed: ${activeDataset}`)
        // console.log(Object.values(generateLayers(activeDataset)).flat()
        //     .filter(x => x.is_selectable)
        //     .map(x => x.id))
        setSelectedFeatures([])
        const layers = generateLayers(activeDataset)
        console.log(layers)
        setSelectableLayers(
            Object.values(generateLayers(activeDataset)).flat()
              .filter(x => x.is_selectable)
              .map(x => x.id)
          )
    }, [activeDataset, layerGroup])

    useEffect(() => {
        selectableLayersRef.current = selectableLayers
        console.log(`SLR: ${selectableLayersRef.current}`)
    }, [selectableLayers])

    useEffect(() => {
        console.log(`SELECTED FEATURES: ${selectedFeatures}`)
    }, [selectedFeatures])

    function setFeatureState_withDependencies(f, state) {
        console.log(f)
        console.log(`LSD: ${layerSelectionDependencies}`)
        map.setFeatureState(
            {
                source: f.layer.source,
                sourceLayer: f.layer['source-layer'],
                id: f.id
            },
            { selected: state }
        )
       layerSelectionDependenciesRef.current && layerSelectionDependenciesRef.current.forEach(lsd => {
            if (lsd && lsd[0] === f.layer['source-layer']) {
                map.setFeatureState(
                    {
                        source: f.layer.source,
                        sourceLayer: lsd[1],
                        id: f.id
                    },
                    { selected: state }
                )
            }
        })
        layerVisibilityDependenciesRef.current && layerVisibilityDependenciesRef.current.forEach(lvd => {
            if (lvd && lvd[0] === f.layer['id']) {
                map.setLayoutProperty(lvd[0], 'visibility', (state === true ? 'none' : 'visible'))
                map.setLayoutProperty(lvd[1], 'visibility', (state === true ? 'visible' : 'none'))
            }
        })
        if (state === true && layerVisibilityDependencies[0]) {
            // Assuming one visibility dependency for the moment
            setSelectableLayers(layerVisibilityDependencies[0])
        }
    }

    useEffect(() => {
        // return
        if (!mapLoaded) return
        mapContainer.current.addEventListener('mousedown', mouseDown, true);
        let start, current, box;

        // Return the xy coordinates of the mouse position
        function mousePos(e) {
            const rect = mapContainer.current.getBoundingClientRect();
            return new mapboxgl.Point(
                e.clientX - rect.left - mapContainer.current.clientLeft,
                e.clientY - rect.top - mapContainer.current.clientTop
            );
        }

        function mouseDown(e) {
            // Continue the rest of the function if the shiftkey is pressed.
            if (!(e.shiftKey && e.button === 0)) return;

            // Disable default drag zooming when the shift key is held down.
            map.dragPan.disable();

            // Call functions for the following events
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
            document.addEventListener('keydown', onKeyDown);

            // Capture the first xy coordinates
            start = mousePos(e);
        }

        function onMouseMove(e) {
            // Capture the ongoing xy coordinates
            current = mousePos(e);

            // Append the box element if it doesnt exist
            if (!box) {
                box = document.createElement('div');
                box.classList.add('boxdraw');
                mapContainer.current.appendChild(box);
            }

            const minX = Math.min(start.x, current.x),
                maxX = Math.max(start.x, current.x),
                minY = Math.min(start.y, current.y),
                maxY = Math.max(start.y, current.y);

            // Adjust width and xy position of the box element ongoing
            const pos = `translate(${minX}px, ${minY}px)`;
            box.style.transform = pos;
            box.style.width = maxX - minX + 'px';
            box.style.height = maxY - minY + 'px';
        }

        function onMouseUp(e) {
            // Capture xy coordinates
            finish([start, mousePos(e)]);
        }

        function onKeyDown(e) {
            // If the ESC key is pressed
            if (e.keyCode === 27) finish();
        }

        function finish(bbox) {
            // Remove these events now that finish has been called.
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('mouseup', onMouseUp);

            if (box) {
                box.parentNode.removeChild(box);
                box = null;
            }

            // If bbox exists. use this value as the argument for `queryRenderedFeatures`
            if (bbox) {
                // Handle small drags via 'click'
                const max_dim = Math.max(Math.abs(bbox[0].x - bbox[1].x), Math.abs(bbox[0].y - bbox[1].y))
                if (max_dim < 5) {
                    map.dragPan.enable();
                    return
                }

                const current_ids = featuresRef.current.map(feat => feat.id)
                const features = map.queryRenderedFeatures(bbox).filter(f => !current_ids.includes(f.id));
                if (features.length >= 1000) {
                    return window.alert('Select a smaller number of features');
                }
                // setSelectedFeatures(features)
                setSelectedFeatures([...new Set([
                    ...featuresRef.current,
                    ...features])])
            }

            map.dragPan.enable();
        }
    }, [mapContainer, mapLoaded])

    useEffect(() => {
        if (!mapLoaded) return
        console.log(selectedFeatures)
        console.log(layerVisibilityDependenciesRef.current)
        featuresRef.current.forEach(f => {
            if ((!selectedFeatures.includes(f))) {
                setFeatureState_withDependencies(f, false)
            }
        })
        selectedFeatures.forEach(f => {
            if (!featuresRef.current.includes(f)) {
                setFeatureState_withDependencies(f, true)
            }
        })
        featuresRef.current = selectedFeatures
    }, [selectedFeatures])


    function onClick(e) {
        function getQueryableFeatures(e) {
            var bbox = [
                [e.point.x - 5, e.point.y - 5],
                [e.point.x + 5, e.point.y + 5]
            ];
            var features = map.queryRenderedFeatures(bbox).filter(
                x => selectableLayersRef.current.includes(x.layer.id)
            );
            return features
        }

        const features = getQueryableFeatures(e)
        const is_shift = e.originalEvent.shiftKey

        // Features were clicked, figure out what to do
        if (features.length !== 0) {
            const f = features[0]
            const current_ids = featuresRef.current.map(feat => feat.id)

            // Set the first feature and dependencies to selected
            // setFeatureState_withDependencies(features[0], true)
            // Decide on adding new features to selected features buffer, or replacing the buffer
            if (is_shift) {
                if (current_ids.includes(f.id)) {
                    setSelectedFeatures([...new Set([...featuresRef.current.filter(feat => feat.id != f.id)])])
                }
                else {
                    setSelectedFeatures([...new Set([...featuresRef.current, f])])
                }
            }
            else {
                const feature_to_add = features[0]
                console.log(feature_to_add)
                console.log(layerVisibilityDependenciesRef.current)
                if (layerVisibilityDependenciesRef.current.length > 0 && feature_to_add.layer.id == layerVisibilityDependenciesRef.current[0][1]) {
                    console.log("Should make visible")
                    console.log(featuresRef.current)
                    console.log(featuresRef.current.concat([feature_to_add]))
                    const feature_to_keep = featuresRef.current.filter(x => x.layer.id==layerVisibilityDependenciesRef.current[0][0])[0]
                    console.log(feature_to_keep)
                    setSelectedFeatures([feature_to_keep, feature_to_add])
                }
                else setSelectedFeatures([feature_to_add])
            }
        }
        else {
            setSelectedFeatures([])
        }
    }

    useEffect(() => {
        layerSelectionDependenciesRef.current = layerSelectionDependencies
        console.log(`Layer Selection Dependencies, ${layerSelectionDependenciesRef.current}`)
    }, [layerSelectionDependencies])

    useEffect(() => {
        layerVisibilityDependenciesRef.current = layerVisibilityDependencies
        console.log(`Layer Visibility Dependencies, ${layerVisibilityDependenciesRef.current}`)
    }, [layerVisibilityDependencies])

    useEffect(() => {
        selectableLayersRef.current = selectableLayers
        console.log(`Selectable Layers, ${layerVisibilityDependenciesRef.current}`)
    }, [selectableLayers])

    useEffect(() => {
        if (!mapLoaded) return
        map.on('click', (e) => {
            onClick(e)
        })
    }, [mapLoaded])

    return { selectedFeatures, selectableLayers }
}
