import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import {LOGIN, MAP} from "../../constants/routes";
import {authContext} from "../../context/authContext";

const PasswordResetDone = () => {
    useEffect(() => {
        document.title = "Password Reset";
    }, []);
    const user = useContext(authContext);
    const navigate = useNavigate();

    if (user) {
        navigate(MAP)
    }

    return (<>
        <main>
            <section style={{marginTop: 30}}>
                <div>
                    <div>
                        <section>
                            <div className="d-flex justify-content-center centered-form">
                                <form>
                                    <h3 style={{textAlign: "center"}}>Password Reset</h3>
                                    <div className="d-flex justify-content-center" style={{width:"300px", textAlign: "center"}}>
                                        <div className="form-group mt-2">
                                            An email containing password reset instructions has been sent to the
                                            provided
                                            email address.
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="form-group mt-3" style={{alignContent: "center"}}>
                                            <button type="submit" className="btn btn-primary"
                                                    onClick={() => navigate(LOGIN)}
                                                    style={{width: "330px", fontWeight: 600}}>
                                                Return to Log In
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </main>
    </>)
}

export default PasswordResetDone