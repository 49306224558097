export default function getLayers(layer_lookup, key, args, protos) {
    console.log("Getting Layers")
    if (Object.keys(layer_lookup).length === 0) {
        console.log('layer_lookup empty')
        return {
            legends: [],
            layers: [],
            selectionDependencies: [],
            visibilityDependencies: [],
        }
    }

    const layers = layer_lookup[key]

    if (!layers || !layers[0]) return {
        legends: [],
        layers: []
    }
    console.log(layers)

    const selectionDependencies = layers.filter(l => l.selection_dependent_on).map(l => [l.selection_dependent_on, l.source_layer])
    const visibilityDependencies = layers.filter(l => l.visibility_dependent_on).map(l => [l.visibility_dependent_on, l.source_layer])
    const layersWithProtos = layers.map(l => new protos[l.layer_type](
        Object.assign(l, args)
    ))
    console.log(layersWithProtos)
    const layers_to_return = layersWithProtos.map(l => l.MBLayer)
    const legends_to_return = layersWithProtos.filter(l => l.display_legend).map(l => l.Legend)
    console.log(legends_to_return)
    console.log("Got Layers")
    return {
        legends: legends_to_return,
        layers: layers_to_return,
        selectionDependencies: selectionDependencies,
        visibilityDependencies: visibilityDependencies,
    }
}