// const national_forests = {
//     id: 'national_forests', 
//     type: 'vector', 
//     format: 'mvt',
//     // tiles: ['https://cl-xyz-tileserver-4gynfkei6q-uw.a.run.app/gddp_heatwaves/{z}/{x}/{y}'],
//     tiles: ['https://cl-xyz-tileserver-4gynfkei6q-uw.a.run.app/lower48/{z}/{x}/{y}'],
//     // tiles: ['https://cl-xyz-tileserver-4gynfkei6q-uw.a.run.app/national_forests/{z}/{x}/{y}'],
//     minzoom: 0,
//     maxzoom: 9
// }
import { tile_server_url, projects, tile_builder_url } from "../config";


const mapbox_dem = {
    'type': 'raster-dem',
    'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
    'tileSize': 512,
    'maxzoom': 14
}

const biomass = {
    'type': 'raster',
    'tiles': [
        'https://raster-tiles-rcp85y50mean-pot-agb-mgcha-500m-4gynfkei6q-uw.a.run.app/static/{z}/{x}/{y}.png'
    ],
    'tileSize': 512,
    'scheme': 'tms' 
}



function generateSources(dataset) {
    console.log(dataset)
    const sources = [
        ['mapbox-dem', mapbox_dem],
        ['biomass', biomass]
    ];

    if (dataset === undefined) return sources
    const base = {
        type: 'vector', 
        format: 'mvt',
        minzoom: 0,
        maxzoom: 9
    }

    var obj = Object.assign({}, base)
    obj['tiles'] = [`${tile_builder_url}/get_xyz/${dataset.id}/{z}/{y}/{x}.pbf`]
    obj['id'] = dataset.id
    const src = [[dataset.id, obj]]
    console.log(obj)
    return sources.concat(src)
}

export default generateSources