import './Tooltip.css';
import { useState, useEffect } from 'react';

export default function Tooltip({trigger, children}){
    const [tooltipOn, setTooltipOn] = useState(false)
    const [tooltipLocation, setTooltipLocation] = useState({})

    function showTooltip(e) {
        setTooltipOn(true)
        setTimeout(() => setTooltipOn(false), 5000)
        setTooltipLocation({"position": "absolute", "left": e.clientX, "top": e.clientY})
    }

    // useEffect(() => {
    //     console.log(tooltipLocation)
    //     console.log(children)
    // }, [tooltipOn])

    return(
        <div className="tooltip-trigger" onMouseEnter={(e) => showTooltip(e)}>
            {trigger}
            <div className='tooltip' style={tooltipLocation}>
                {children}
            </div>
        </div>
    )
}