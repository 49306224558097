import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

const config = {
    apiKey: "AIzaSyCuJyT7I-a9qnPvlJJf3Sez51ZLo2_MfaE",
    authDomain: "sust-dev-03.firebaseapp.com",
    projectId: "sust-dev-03",
};

const tenantId = process.env.REACT_APP_FIREBASE_TENANT
export const useLocalLogin = !!process.env.REACT_APP_USE_LOCAL_LOGIN
console.log(`[FIREBASE] Tenant ID: ${tenantId}`)

const app = initializeApp(config);
export const auth = getAuth(app);
auth.tenantId = tenantId
