import {
    Blue_5Step,
    Blue_5Step_0_1,
    YlOrRd_5Step,
    YlOrRd_5Step_2,
    YlOrRd_5Step_0_10,
    Green_Yellow_Red_5Step_n1000_1000,
    Blue_5Step_Small,
    Blue_5Step_per_ha,
    SelectedTessela,
    SelectedTessela2,
    SelectedTessela3,
    FloodMaps_Bathy,
    Empty,
    Blue_5Step_Mid
} from "../maphooks/maphooks/layers/colormaps"

export const layer_titles = {
    "ElDorado": "El Dorado National Forest",
    "VCS1477b_earthquake": "Peak Ground Acceleration",
}

export function getLayerTitle(layer) {
    // if (Object.keys(layer_titles).includes(layer)) return layer_titles[layer]
    // return layer
    return layer
}

const biomass = {
    'id': 'biomass',
    'layer_type': 'RASTER',
    'layer': {
        'id': 'biomass',
        'type': 'raster',
        'source': 'biomass',
        'raster-opacity': 0.5,
        'minzoom': 2,
        'maxzoom': 11
    }
}

function generateLayers(dataset) {
    if (dataset === undefined) return {}
    const base = dataset.id

    // const labels = ['', '_baseline_area', '_baseline_area_wildfire_grid', '_wildfire_grid', '_baseline_area_historic_grid', '_historic_grid', '_biomass', '_earthquake', '_pest']

    if (dataset.type == "Forest Carbon"){
      const labels = [
          'wildfire_ai',
          'carbon_percent_loss',
          'water_balance',
          'pest',
          'pga',
          'fire_obs'
      ]
      const legends = {
          'wildfire_ai': YlOrRd_5Step,
          'carbon_percent_loss': YlOrRd_5Step,
          'water_balance': YlOrRd_5Step_0_10,
          'pest': YlOrRd_5Step_0_10,
          'pest': YlOrRd_5Step_0_10,
          'pga': YlOrRd_5Step_2,
          'fire_obs': YlOrRd_5Step
      }

      const color_values = {
          'carbon_percent_loss': 'carbon_percent_loss_2022_2023_MEAN_carbon_percent_loss',
          'wildfire_ai': 'wildfire_ai_2023_2053_MEAN_ssp585-mid',
          'water_balance': 'water_balance_2022_2072_MEAN_water_balance',
          'pga': 'pga_2022_2023_MEAN_pga',
          'pest': 'pest_2022_2023_MEAN_pest',
          'fire_obs': 'fire_obs_2000-11-01_2022-12-01_MEAN_historic',
      }

      const layers = Object()

      labels.map(label => {
          let y;
          if (label === 'pga') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                  {
                  id: `${label}`,
                  source: base,
                  source_layer: `summary-all`,
                  colorValue: ['to-number', ['get', color_values[`${label}`]]],
                  opacity: 1.0,
                  maxzoom: 17,
                  layer_title: getLayerTitle(label),
                  layer_type: 'FILL_WITH_OUTLINE',
                  legend: legends[label],
                  display_legend: false,
                  is_selectable: true
              }]
          }
          else if (label === 'pest') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                  {
                  id: `${label}`,
                  source: base,
                  source_layer: `summary-all`,
                  colorValue: ['to-number', ['get', color_values[`${label}`]]],
                  opacity: 1.0,
                  maxzoom: 17,
                  layer_title: getLayerTitle(label),
                  layer_type: 'FILL_WITH_OUTLINE',
                  legend: legends[label],
                  display_legend: false,
                  is_selectable: true
              }]
          }
          else if (label === 'water_balance') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                  {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: true,
                      is_selectable: true
              }]
          }
          else if (label === 'carbon_percent_loss') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                  {
                  id: `${label}`,
                  source: base,
                  source_layer: `summary-all`,
                  colorValue: ['to-number', ['get', color_values[`${label}`]]],
                  opacity: 1.0,
                  maxzoom: 17,
                  layer_title: getLayerTitle(label),
                  layer_type: 'FILL_WITH_OUTLINE',
                  legend: legends[label],
                  display_legend: true,
                  is_selectable: true
              }]
          }
          else if (label === 'wildfire_ai') {
              y = [
                  {
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                  {
                      id: `${label}_grid_outline`,
                      source: base,
                      source_layer: `grid-${label}`,
                      // colorValue: ['to-number', ['get', color_values[`_wildfire_grid`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela3,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `grid-${label}`,
                      visibility_dependent_on: `${label}`,
                      visible: false
                  },
                  {
                      id: `grid-${label}`,
                      source: base,
                      source_layer: `grid-${label}`,
                      // colorValue: ['rgb', 0, 0, 0],
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: false,
                      is_selectable: true,
                      visibility_dependent_on: `${label}`,
                      visible: false
                  },
                  {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: true,
                      is_selectable: true
                  }
              ]
          }
          else if (label === 'fire_obs') {
              y = [
                  {
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                  {
                      id: `${label}_grid_outline`,
                      source: base,
                      source_layer: `grid-${label}`,
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `grid-${label}`,
                      visibility_dependent_on: `${label}`,
                      visible: false
                  },
                  {
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                  {
                      id: `grid-${label}`,
                      source: base,
                      source_layer: `grid-${label}`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: false,
                      is_selectable: false,
                      // visibility_dependent_on: `${label}`,
                      visible: true
                  },
                  // {
                  //     id: `${label}`,
                  //     source: base,
                  //     source_layer: `summary-all`,
                  //     colorValue: ['to-number', ['get', color_values[`${label}`]]],
                  //     opacity: 1.0,
                  //     maxzoom: 17,
                  //     layer_title: getLayerTitle(label),
                  //     layer_type: 'FILL_WITH_OUTLINE',
                  //     legend: legends[label],
                  //     display_legend: true,
                  //     is_selectable: true
                  // }
              ]
          }
          layers[label] = y
      })
      console.log(layers)
      // console.log(layers['ACR255'])
      return layers
    }

    if (dataset.type == "Blue Carbon"){
      const labels = [
          'extreme_precip',
          'cyclone',
          'cyclone_obs',
          'slr',
          'pest',
          'pga'
      ]
      const legends = {
          // '': YlOrRd_5Step,
          // '_baseline_area': YlOrRd_5Step, 
          // '_baseline_area_wildfire_grid': YlOrRd_5Step,
          'extreme_precip': YlOrRd_5Step,
          'cyclone': YlOrRd_5Step,
          'cyclone_obs': YlOrRd_5Step_0_10,
          'slr': YlOrRd_5Step_0_10,
          'pest': YlOrRd_5Step_0_10,
          'pga': YlOrRd_5Step_2,
      }

      const color_values = {
          'extreme_precip': 'extreme_precip_2022_2023_MEAN_ssp585-mid',
          'cyclone': 'cyclone_2010_2011_MEAN_ssp585-mid',
          'cyclone_obs': 'cyclone_obs_2010-01-01_2022-12-01_MEAN_historic',
          'slr': 'slr_2022_2023_MEAN_ssp585-mid',
          'pga': 'pga_2022_2023_MEAN_pga',
          'pest': 'pest_2022_2023_MEAN_pest'
      }

      const layers = Object()

      labels.map(label => {
          let y;
          if (label === 'pga') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: false,
                      is_selectable: true
              }]
          }
          else if (label === 'pest') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: false,
                      is_selectable: false
              }]
          }
          else if (label === 'cyclone_obs') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: true,
                      is_selectable: true
              }]
          }
          else if (label === 'slr') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: true,
                      is_selectable: true
              }]
          }
          else if (label === 'cyclone') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                  },
                {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: true,
                      is_selectable: true
              }]
          }
          else if (label === 'extreme_precip') {
              y = [{
                      id: `${label}_outline`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: `${base}${label}`,
                      layer_type: 'SIMPLE_OUTLINE',
                      legend: SelectedTessela,
                      display_legend: false,
                      is_selectable: false,
                      selection_dependent_on: `${label}`
                },
                {
                      id: `${label}`,
                      source: base,
                      source_layer: `summary-all`,
                      colorValue: ['to-number', ['get', color_values[`${label}`]]],
                      opacity: 1.0,
                      maxzoom: 17,
                      layer_title: getLayerTitle(label),
                      layer_type: 'FILL_WITH_OUTLINE',
                      legend: legends[label],
                      display_legend: true,
                      is_selectable: true
              }]
          }
          layers[label] = y
      })
      console.log(layers)
      // console.log(layers['ACR255'])
      return layers
    }
}


export default generateLayers
