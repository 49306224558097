import { useState, useEffect, useContext } from "react";
import {authContext} from "../context/authContext";
import { tile_builder_url } from "../config";
import Dropdown from "./dropdown";
import FileSubmission from "./file_submission/FileSubmission";
import "./layer_picker.css"

export default function LayerPicker({activeDataset, setActiveDataset, allDatasets, setAllDatasets, availableLayers, layerGroup, setLayerGroup, mapLoaded, project}) {
    const user = useContext(authContext);

    useEffect(() => {
        user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${tile_builder_url}/projects`, {
                        method: 'GET',
                        withCredentials: true,
                        credentials: 'include',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': project,
                        }
                    })
                        .then(r => r.json())
                        .then(r => setAllDatasets(r))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        console.log(activeDataset)
        console.log(availableLayers)
    }, [activeDataset])

    return (
        <div className="layer-picker">
            <Dropdown activeDataset={activeDataset} 
                allDatasets={allDatasets} 
                setActiveDataset={setActiveDataset} 
                availableLayers={availableLayers} 
                layerGroup={layerGroup} 
                setLayerGroup={setLayerGroup}
                mapLoaded={mapLoaded}
                project={project}/>
        </div>
    );
}