import { useState, useEffect, useRef, useMemo } from 'react'
import getLayers from './getLayer'

// Data
import sky from './sky'
// PROTOS
import { all_protos } from './protos/all_protos'

export function useLayers(map, mapLoaded, style, all_layers, all_sources, activeDataset, custom_protos = null) {
    /**
   * Maintains the loaded sources and layers for a MapboxGL Map.  Allows for switching of groups of layers.  
   * @param map  MapboxGL map
   * @param mapLoaded   Boolean state watcher to ensure the map is ready
   * @param style  Map style.  React State from useMap.  Layers must be reloaded when the style changes.
   * @param all_layers   An object mapping layer_groups to sets of layers
   * @param all_sources All sources used amongst layers
   * @param custom_protos  Custom layer parsers, which lets you create your own symbology.
   * @return {Object} layerGroup, layerSelectionDependencies, subgroup, subgroupOn, setLayerGroup, setSubgroup
   */
    // console.log(all_sources)
    // console.log(all_sources)
    const [layerGroup, setLayerGroup] = useState([])
    const layersRef = useRef([])
    const [layers, setLayers] = useState([])

    const layers_and_legends = useMemo(
        () => {
            console.log(all_layers)
            return getLayers(all_layers, layerGroup, { 'floodGroup': '' }, custom_protos || all_protos)
        },
        [layerGroup, activeDataset]
    )

    useEffect(
        () => setLayers(layers_and_legends.layers), 
        [mapLoaded, layers_and_legends, activeDataset]
    )
    // const layers = useMemo(
    //     () => layers_and_legends.layers, [mapLoaded, layers_and_legends])
    const layerSelectionDependencies = useMemo(
        () => layers_and_legends.selectionDependencies, [mapLoaded, layers_and_legends])
    const layerVisibilityDependencies = useMemo(
        () => layers_and_legends.visibilityDependencies, [mapLoaded, layers_and_legends])


    function unloadLayers() {
        for (const existing of layersRef.current) {
            map.removeLayer(existing.id)
        }
    }

    function addSourcesAndSupps() {
        const existing_sources = map.getStyle().sources
        // console.log(all_sources)
        for (const source of all_sources) {
            if (!Object.keys(existing_sources).includes(source[0])) {
                map.addSource(source[0], source[1])
            }
        }
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
        map.setFog({ 'color': 'rgba(255, 255, 255, 0.82)' });
    }

    function addLayers(layers_to_add) {
        for (const layer of layers_to_add) {
            // console.log(layer)
            map.addLayer(layer)
        }
        map.addLayer(sky)
        layersRef.current = [...layers_to_add, sky]
    }

    useEffect(() => {
        if (!mapLoaded) return
        map.setStyle(style)
        map.on('style.load', () => {
            unloadLayers(layers)
            addSourcesAndSupps()
            addLayers(layers)
        })
    }, [style])

    useEffect(() => {
        if (!mapLoaded) return
        unloadLayers(layers)
        addSourcesAndSupps()
        addLayers(layers)
    }, [mapLoaded, layers])

    return {
        layerGroup,
        layerSelectionDependencies,
        layerVisibilityDependencies,
        setLayerGroup
    }
}

