import React from 'react';
import { CartesianGrid, Legend, Line, Label, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Bar, BarChart, Cell } from 'recharts';
import './stats_panel.css'
import { getLayerTitle } from '../layers/offsets_demo_layers';
import { BinnedGraph } from './binned_graph';

function formatYAxis(value) {
    return `${(value * 100).toFixed(1)}%`
}

function BasicMetric({ title, value, label, dataKey, marker, binCount }) {
    return <div className='basic-metric'>
        <div className='metric-title'>
            {title}
        </div>
        <div className='stats-box'>
            {label &&
                <div className='label'>
                    ({label})
                </div>
            }
            <div className='value'>
                {value}
            </div>
            <div>
                <BinnedGraph
                    dataKey={dataKey}
                    marker={marker}
                    binCount={binCount}
                />
            </div>
        </div>
    </div>
}

function BasicMetricWithOverride({ title, value, label, dataKey, overrideMarker, binCount }) {
    return <div className='basic-metric'>
        <div className='metric-title'>
            {title}
        </div>
        <div className='stats-box'>
            {label &&
                <div className='label'>
                    ({label})
                </div>
            }
            <div className='value'>
                {value}
            </div>
            <div>
                <BinnedGraph
                    dataKey={dataKey}
                    overrideMarker={overrideMarker}
                    binCount={binCount}
                />
            </div>
        </div>
    </div>
}

export function _BarChart({ data, selectableLayers, activeDataset, layerGroup }) {
    if (data.length === 0) return <></>
    const _data = data.filter(x => selectableLayers.includes(x.layer.id))

    if (_data.length === 0) return <></>

    let layerSplits = Object()
    selectableLayers.forEach((layer) => {
        layerSplits[layer] = _data.filter(d => d.layer.id === layer)
    })
    let panels = []

    if (!Object.keys(layerSplits).includes(layerGroup)) return <></>

    if (activeDataset.type == 'Forest Carbon') {
        if (layerGroup === 'cyclone') return <></>
        if (layerSplits[layerGroup].length > 0 && activeDataset.type == 'Forest Carbon') {
            const data_to_plot = [{
                "year": "2023-2053",
                "Project": layerSplits[layerGroup][0].properties['wildfire_ai_2023_2053_MEAN_ssp585-mid'].toFixed(3),
            },
            {
                "year": "2040-2070",
                "Project": layerSplits[layerGroup][0].properties['wildfire_ai_2040_2070_MEAN_ssp585-mid'].toFixed(3)
            }]

            panels.push(
                <div className='basic-metric'>
                    <div className='metric-title'>
                        Future Wildfire (Project Average)
                    </div>
                    <div className='label'>
                        Expected burned area per year
                    </div>
                    <ResponsiveContainer width={500} aspect={1.5}>
                        <BarChart width={730} height={550} data={data_to_plot} margin={{ top: 20, right: 30, left: 0, bottom: 60 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis stroke="#404040" dataKey="year" angle={0} padding={{ bottom: 20 }} tickMargin={25} />
                            <YAxis stroke="#404040" tickFormatter={formatYAxis} />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar dataKey="Project" fill="#00A181" />
                            {/* <Legend verticalAlign="top" height={50} /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            )
        }

        const barColors = ["#ff7f0e", "#FF4747"]
        if (layerSplits[`grid-wildfire_ai`].length > 0 && activeDataset.type == 'Forest Carbon') {
            const data_to_plot = [{
                "year": "2023-2052",
                "value": layerSplits[`grid-wildfire_ai`][0].properties['wildfire_ai_2023_2053_MEAN_ssp585-mid'].toFixed(3),
            }, {
                "year": "2043-2072",
                "value": layerSplits[`grid-wildfire_ai`][0].properties['wildfire_ai_2040_2070_MEAN_ssp585-mid'].toFixed(3),
            }]
            panels.push(
                <div className='basic-metric'>
                    <div className='metric-title'>
                        Future Wildfire (300m)
                    </div>
                    <div className='label'>
                        Annual Probability of Wildfire
                    </div>
                    <ResponsiveContainer width={500} aspect={1.5}>
                        <BarChart width={730} height={550} data={data_to_plot} margin={{ top: 20, right: 30, left: 0, bottom: 60 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis stroke="#404040" dataKey="year" angle={0} padding={{ bottom: 40 }} tickMargin={25} />
                            <YAxis stroke="#404040" tickFormatter={formatYAxis} />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar
                                dataKey="value"
                                fill="#00a0fc"
                                stroke="#000000"
                                strokeWidth={1}
                            >
                                {
                                    data_to_plot.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            )
        }

        if (layerSplits[layerGroup].length > 0) {
            return <div className='stats-panel'>
                <h1>{getLayerTitle(activeDataset.project_name)}</h1>
                {panels.map((i) => <div key={i}>{i}</div>)}
                <hr></hr>
                <BasicMetric
                    title='Future Wildfire'
                    value={`${layerSplits[layerGroup][0].properties['wildfire_ai_2023_2053_MEAN_ssp585-mid'].toFixed(2)}`}
                    label='project level probability of wildfire in project perimeter'
                    dataKey={'wildfire_ai_2023_2053'}
                    marker={layerSplits[layerGroup][0].properties['wildfire_ai_2023_2053_MEAN_ssp585-mid'].toFixed(2)}
                    binCount={10.0}
                />
                <BasicMetric
                    title='Carbon Loss From Wildfire'
                    value={`${(layerSplits[layerGroup][0].properties.carbon_percent_loss_2022_2023_MEAN_carbon_percent_loss).toFixed(2)}`}
                    label='projected carbon loss from wildfire as a percentage of above ground biomass'
                    dataKey={'carbon_percent_loss_2022_2023'}
                    marker={layerSplits[layerGroup][0].properties.carbon_percent_loss_2022_2023_MEAN_carbon_percent_loss}
                    binCount={10.0}
                />
                <BasicMetric
                    title='Historic Wildfire'
                    value={`${(layerSplits[layerGroup][0].properties['fire_obs_2000-11-01_2022-12-01_MEAN_historic'] * 100).toFixed(2)}`}
                    label='average annual burned area since 2001 as % of total project area'
                    dataKey={'fire_obs_2000-11-01_2022-12-01'}
                    marker={layerSplits[layerGroup][0].properties['fire_obs_2000-11-01_2022-12-01_MEAN_historic']}
                    binCount={10.0}
                />
                <BasicMetric
                    title='Water Availability'
                    value={`${(layerSplits[layerGroup][0].properties.water_balance_2022_2072_MEAN_water_balance).toFixed(0)}`}
                    label='average net water balance over 2022-2072 in mm/yr'
                    dataKey={'water_balance_2022_2072'}
                    marker={layerSplits[layerGroup][0].properties.water_balance_2022_2072_MEAN_water_balance}
                    binCount={10.0}
                />
                <BasicMetricWithOverride
                    title='Pest and Disease'
                    value={layerSplits[layerGroup][0].properties.pest_2022_2023_MEAN_pest.toFixed(0)}
                    label='score based on the likelihood of invasive species and impactful diseases'
                    dataKey={'pest_2022_2023'}
                    overrideMarker={Math.trunc(layerSplits[layerGroup][0].properties.pest_2022_2023_MEAN_pest)}
                    binCount={5.0}
                />
                <BasicMetric
                    title='Seismic'
                    value={`${(layerSplits[layerGroup][0].properties.pga_2022_2023_MEAN_pga).toFixed(2)}`}
                    label='Peak Ground Acceleration in m/s^2'
                    dataKey={'pga_2022_2023'}
                    marker={layerSplits[layerGroup][0].properties.pga_2022_2023_MEAN_pga}
                    binCount={10.0}
                />
            </div>
        }
        else {
            return <></>
        }
    }
    else if (activeDataset.type == 'Blue Carbon') {
        panels.push(<span style={{fontSize:'10px', paddingLeft:'500px'}}>&nbsp;</span>)
        if (layerGroup === 'wildfire_ai') return <></>
        if (layerSplits[layerGroup].length > 0) {
            return <div className='stats-panel'>
                <h1>{getLayerTitle(activeDataset.project_name)}</h1>
                {panels.map((i) => <div key={i}>{i}</div>)}
                <hr></hr>
                <BasicMetric
                    title='Historic Cyclones'
                    value={`${(layerSplits[layerGroup][0].properties['cyclone_obs_2010-01-01_2022-12-01_MEAN_historic']).toFixed(2)}`}
                    label='project level probability of cyclones in project perimeter'
                    dataKey={'cyclone_obs_2010-01-01_2022-12-01'}
                    marker={layerSplits[layerGroup][0].properties['cyclone_obs_2010-01-01_2022-12-01_MEAN_historic']}
                    binCount={10.0}
                />
                <BasicMetric
                    title='Future Cyclones'
                    value={`${(layerSplits[layerGroup][0].properties['cyclone_2010_2011_MEAN_ssp585-mid'] * 100).toFixed(2)}`}
                    label='project level probability of cyclones in project perimeter'
                    dataKey={'cyclone_2010_2011'}
                    marker={layerSplits[layerGroup][0].properties['cyclone_2010_2011_MEAN_ssp585-mid']}
                    binCount={10.0}
                />
                <BasicMetric
                    title='Future Sea Level Rise'
                    value={`${(layerSplits[layerGroup][0].properties['slr_2022_2023_MEAN_ssp585-mid']).toFixed(2)}`}
                    label='projected increase in sea level rise in meters'
                    dataKey={'slr_2022_2023'}
                    marker={layerSplits[layerGroup][0].properties['slr_2022_2023_MEAN_ssp585-mid']}
                    binCount={10.0}
                />
                <BasicMetric
                    title='Future Extreme Precipitation'
                    value={`${(layerSplits[layerGroup][0].properties['extreme_precip_2022_2023_MEAN_ssp585-mid']).toFixed(2)}`}
                    label='projected days with >2in of precipitation per year'
                    dataKey={'extreme_precip_2022_2023'}
                    marker={layerSplits[layerGroup][0].properties['extreme_precip_2022_2023_MEAN_ssp585-mid']}
                    binCount={10.0}
                />
                <BasicMetric
                    title='Pest and Disease'
                    value={layerSplits[layerGroup][0].properties.pest_2022_2023_MEAN_pest.toFixed(0)}
                    label='score based on the likelihood of invasive species and impactful diseases'
                    dataKey={'blue_pest_2022_2023'}
                    overrideMarker={Math.trunc(layerSplits[layerGroup][0].properties.pest_2022_2023_MEAN_pest)}
                    binCount={5.0}
                />
                <BasicMetric
                    title='Seismic'
                    value={`${(layerSplits[layerGroup][0].properties.pga_2022_2023_MEAN_pga).toFixed(2)}`}
                    label='Peak Ground Acceleration in m/s^2'
                    dataKey={'blue_pga_2022_2023'}
                    marker={layerSplits[layerGroup][0].properties.pga_2022_2023_MEAN_pga}
                    binCount={10.0}
                />
            </div>
        }
        else {
            return <></>
        }
    }
}
